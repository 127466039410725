<template>

  <!-- <div stype="margin: 10px 0">
    B站所有的付费笔记、源码、代码生成器、成品项目等都打包在VIP群<br><br>
      5.5 - 5.31 活动价永久VIP打6折仅需 66 元，机不可失，时不再来！<br><br>
      如果需要可以加我钉钉：xiaqing1993，微信：xia_qing2012
  </div>
   -->

  <div>
    <img src="static/index.jpeg">
  </div>
  
   

<div id="app">
   
    <el-upload
            class="avatar-uploader"
            action="api/order/uploadFile"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <el-button type="primary">主要按钮</el-button>
    </el-upload>

</div>

</template>

<script>
import request from "@/utils/request";

export default {
  name: "Home",
  data() {
    return {
      imageUrl:"",
    }
  },
  mounted() {
  },
  methods: {
          //上传成功后触发的方法
            handleAvatarSuccess(res, file) {
                this.imageUrl=res.data;
            },
            //上传前触发的方法
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || 'image/png'|| 'image/xlsx'|| 'image/xls';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
     }
}



</script>

<style scoped>

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>



